import React, { useRef } from 'react'
import Theme2 from '../Theme/Theme2';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LeftSideBar from './LeftSideBar';
import { Container } from 'react-bootstrap';
import TopBar from './TopBar';
import '../CSS/home.css'
import NativeAds from './NativeAds';
import BannerAd from './BannerAds';


export default function HomeComp() {



    const componentARef = useRef();

    const handleReset = () => {
        if (componentARef.current) {
            componentARef.current.resetEverything();
        }
    }


    return (
        <Container>

            <Row>
                <Col xl={9} className="main-col">
                    <TopBar />
                    <div className='quotation-div-ad'>
                        <BannerAd />
                    </div>
                    <Theme2 ref={componentARef} />
                </Col>
                <Col className='col3'>
                    <LeftSideBar handleReset={handleReset} />
                </Col>
            </Row>

        </Container>
    )
}
