import React from 'react'
import { Helmet } from 'react-helmet'

export default function NativeAds() {
    return (
        <>
            <Helmet>
                <script async="async" data-cfasync="false" src="//pl23858541.highrevenuenetwork.com/efe2cc7f606c5c302f9378b45de72125/invoke.js"></script>
            </Helmet>
            <div id="container-efe2cc7f606c5c302f9378b45de72125"></div>
        </>
    )
}
