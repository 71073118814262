import React from 'react'
import '../CSS/leftsidebar.css'


export default function LeftSideBar({ handleReset }) {

    return (
        <div className='side-bar-element-back bottom'>
            <button onClick={handleReset} className='reset-btn'>Reset</button>
        </div >
    )
}
