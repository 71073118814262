import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function AlertBox({ istate, setstate }) {

    const handleClose = () => setstate(false);
    const handleShow = () => setstate(true);


    return (
        <>

            <Modal show={istate} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Download Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>Please fill in all <span style={{ color: 'red' }}>red-marked</span> fields.</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
