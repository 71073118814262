import React, { useEffect, useLocation } from 'react'
import '../CSS/privacy.css'
import { Container } from 'react-bootstrap'

export default function Privacy() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='div-main-privacy'>
            <Container>
                <div className='content-holder-terms'>
                    <h1>Privacy Policy</h1>
                    <h6 className='mb-4'>Effective Date: 20-07-2024</h6>

                    <p>At Freeinvoice.live, we prioritize your privacy and are committed to protecting any personal information you may provide while using our website. This privacy policy outlines the types of information we collect, how we use it, and the measures we take to ensure your data remains secure.
                    </p>

                    <h5>1. Information Collection</h5>
                    <p>By accessing and using Freeinvoice.live ("the Website"), you accept and agree to be bound by the terms and provisions of this agreement. If you do not agree to abide by these terms, please do not use this Website.
                    </p>

                    <h5>2. Use of Information
                    </h5>
                    <p>Since we do not collect any personal information, there is no data for us to use. All invoice generation processes occur on your local device without transmitting data to our servers.
                    </p>

                    <h5>3. Third-Party Sharing</h5>
                    <p>We do not share any personal information with third parties. However, our website displays ads from third-party advertisers. These advertisers may collect information about your browsing habits to provide personalized ads. Please review the privacy policies of these advertisers for more information on their data collection practices.</p>

                    <h5>4. Data Retention</h5>
                    <p>As there is no data collected, there is no data retention policy needed. All invoice-related data is generated and stored locally on your device.</p>

                    <h5>5. Security Measures</h5>
                    <p>We take security seriously and have implemented SSL (Secure Socket Layer) to encrypt all data transmitted between your browser and our website. This ensures that your connection to Freeinvoice.live is secure and that your data remains private.</p>

                    <h5>6. User Rights</h5>
                    <p>Since we do not collect any personal information, there are no personal data records for you to access, correct, or delete.</p>


                    <h5>7. Children’s Privacy
                    </h5>
                    <p>Freeinvoice.live is available for use by individuals of any age, provided they are legally permitted to generate invoices or quotations in their respective countries. We do not knowingly collect personal information from children.</p>

                    <h5>8. Changes to the Privacy Policy
                    </h5>
                    <p>We may update this privacy policy from time to time. The most current version of the policy will always be available on our website's privacy policy page. We encourage you to check this page regularly for any updates.</p>

                    <h5>9. Contact Information
                    </h5>
                    <p>If you have any questions or concerns about this privacy policy, please contact us at:
                    </p>

                    <p><strong>Email:</strong> hello@rohitsingh.pro
                    </p>

                    <p>By using Freeinvoice.live, you agree to this privacy policy and its terms. Thank you for trusting us with your invoicing needs.
                    </p>

                </div>

            </Container>

        </div>
    )
}
