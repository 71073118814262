import React from 'react'
import '../CSS/topbar.css'
import { Button, Stack } from 'react-bootstrap'
import { Link } from 'react-router-dom';

export default function TopBar() {
    return (
        <div className='top-bar-element'>
            <Stack>
                <h1 className='top-bar-heading'>100% Free GST Invoice Generator</h1>
                <p className='top-bar-para'>Freeinvoice.live is a completely free online GST invoice generator. Our free bill generator does not require users to log in or register. This invoice app does not add any unnecessary watermark to the invoice.
                    In short, no login, no watermark, and a totally free online GST bill generator. The output of the file is a PDF.
                    This free bill generator app also allows you to generate unlimited invoices.</p>

                <p className='top-bar-para'> Freeinvoice.live is the best free online invoice generator app. Creating invoices in this free app is very easy and straightforward. Enjoy our lifetime free invoice/bill generator.</p>

                <h5 className='top-bar-para'>Looking for something else? Try our free Quotation generator.</h5>
                <nav className='nav-footer'><Link to="/quotation-generator" className='link'><Button className='button-quotation'>Free Quotation Generator</Button></Link></nav>
            </Stack>
        </div>

    )
}
