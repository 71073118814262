import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import '../CSS/NavBar.css';
import logo from '../logo/invoice-app-logo.png';

export default function CollapsibleExample({ title }) {
    return (
        <header>
            <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
                <Container>
                    <Navbar.Brand className='font-h6'> <img
                        src={logo}
                        width="30"
                        height="30"
                        className="d-inline-block align-top img-navbar"
                        alt="React Bootstrap logo"
                    />{title}</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav" >
                        <Nav className="me-auto ">
                            <Nav.Link><Link className='link-main' to="/">Invoice Generator</Link></Nav.Link>
                            <Nav.Link > <Link className='link-main' to="/quotation-generator" >Quotation Generator</Link></Nav.Link>
                            <Nav.Link ><Link className='link-main' to="/about" >About</Link></Nav.Link>
                        </Nav>

                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
}

