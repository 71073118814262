import React, { useEffect, useRef } from 'react';

const BannerAd = () => {
    const bannerRef = useRef(null);

    const atOptions = {
        key: '565f6be7058ede09ba37841d9dfd9149',
        format: 'iframe',
        height: 250,
        width: 300,
        params: {},
    };

    useEffect(() => {
        if (bannerRef.current && !bannerRef.current.firstChild) {
            const conf = document.createElement('script');
            const script = document.createElement('script');

            conf.type = 'text/javascript';
            script.type = 'text/javascript';

            conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`;
            script.src = `//www.topcreativeformat.com/${atOptions.key}/invoke.js`;

            bannerRef.current.appendChild(conf);
            bannerRef.current.appendChild(script);
        }
    }, [bannerRef]);

    return <div ref={bannerRef} className="banner-ad-container"></div>;
};

export default BannerAd;