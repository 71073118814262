import { Col, Nav, Row } from 'react-bootstrap'
import '../CSS/footer.css'
import { Link } from 'react-router-dom'


export default function Footer() {


    return (
        <footer className='footer-main'>
            <div className='container'>
                <Row >
                    <Col md={7} xl={6} className="justify-content-center" >
                        <h2>Freeinvoice.live</h2>
                        <p>Generating an invoice for free has never been this easy. Our free invoice generator also provides the option to enter GST for each individual product.
                            This website also offers a 100% free quotation generator, which also includes the option to add GST for each product.
                            If you are looking for a lifetime free invoice or quotation generator with dynamic options, this is the right place. </p>
                    </Col>
                    <Col style={{ marginTop: '5 %' }}>
                        <Nav defaultActiveKey="/home" className="justify-content-center">
                            <Nav.Link eventKey="link-1"><Link className='link-main-footer' to='/quotation-generator'>Free Quotation Generator</Link></Nav.Link>
                            <Nav.Link eventKey="link-2"><Link className='link-main-footer' to='/'>Free Invoice Generator</Link></Nav.Link>
                            <Nav.Link eventKey="link-3"><Link className='link-main-footer' to='/about'>About</Link></Nav.Link>
                            <Nav.Link eventKey="link-4"><Link className='link-main-footer' to='/terms-of-use'>Terms Of Use</Link></Nav.Link>
                            <Nav.Link eventKey="link-5"><Link className='link-main-footer' to='/privacy-policy'>Privacy Policy</Link></Nav.Link>
                        </Nav>

                    </Col>
                </Row>
                <p className='p-footer-copywrite'>&copy; 2024 Freeinvoice.live. All Rights Reserved.</p>
            </div>
        </footer>

    )
}
