import React, { useRef } from 'react'
import '../CSS/quotation.css'
import { Col, Container, Row } from 'react-bootstrap'
import TopBar2 from '../components/TopBar2'
import LeftSideBar from '../components/LeftSideBar'
import QuotationTheme from '../Theme/QuotationTheme'
import BannerAd from '../components/BannerAds'


export default function Quotation() {

    const componentARef = useRef(null);

    const handleReset = () => {
        if (componentARef.current) {
            componentARef.current.resetEverything();
        }
    }

    return (
        <Container>


            <Row>
                <Col xl={9} className="main-col">
                    <TopBar2 />
                    <div className='quotation-div-ad'>
                        <BannerAd />
                    </div>

                    <QuotationTheme ref={componentARef} />
                </Col>
                <Col className='col3'>
                    <LeftSideBar handleReset={handleReset} />
                </Col>
            </Row>


        </Container>
    )
}





