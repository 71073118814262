import jsPDF from 'jspdf';
import 'jspdf-autotable';



const formatIndianCurrency = (number) => {
    // Format number with Indian currency options
    const formattedNumber = Number(number).toLocaleString('en-IN', {
        style: 'currency',
        currency: 'INR'
    });

    // Return the formatted number as a string

    return formattedNumber.toString().slice(1);
};

const formatDateString = (value) => {
    // This function formats the date string to dd/mm/yyyy
    if (value) {
        const [year, month, day] = value.split('-');
        return `${day}/${month}/${year}`;
    }
    else {
        return "";
    }


};



export const getMyPDF = (data) => {

    {/* ------------------------------------------------------ Editable Section */ }


    const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'in',
        format: [8.28, 11.71] // width and height in mm
    });





    const columns = ["Item name", "GST%", "Quantity", "Rate", "Tax Amount", "Amount", "Total"];







    const marginX = 0.5;
    const marginY = 0.8;
    const billMarginBody = 0;
    let yPostionLeft = 0;
    let yPostionRight = 0;
    let yPostionLeftTemp = 0;
    const pageHeight = doc.internal.pageSize.height;
    const pageWidth = doc.internal.pageSize.width;

    const YpositionLeft = (pos = null, reset = false) => {
        if (reset !== true) {
            if (pos !== null) {
                return yPostionLeft += pos;
            }
            else {
                return yPostionLeft
            }
        }
        else {
            yPostionLeft = pos;
            return yPostionLeft;
        }

    }

    const YpositionLeftTemp = (pos = null, reset = false) => {
        if (reset !== true) {
            if (pos !== null) {
                return yPostionLeftTemp += pos;
            }
            else {
                return yPostionLeftTemp
            }
        }
        else {
            yPostionLeftTemp = pos;
            return yPostionLeftTemp;
        }
    }

    const YpositionRight = (pos = null) => {
        if (pos !== null) {
            return yPostionRight += pos;
        }
        else {
            return yPostionRight
        }
    }




    // Set font for "Invoice" text
    doc.setFont('Helvetica');

    doc.setFontSize(22);
    doc.setTextColor(0, 0, 0)
    doc.text(data.name, marginX, YpositionLeft(marginY)); // x, y position on the page
    doc.setTextColor(0, 0, 0)
    doc.setFontSize(9);
    doc.text(data.name + " No # " + data.invoiceno || '', marginX, YpositionLeft(0.25));
    doc.setFont('Helvetica', 'bold');
    doc.text(data.name + " Date # " + formatDateString(data.date), marginX, YpositionLeft(0.2));
    if (data.name === 'Invoice') {
        doc.text("Invoice Due Date # " + formatDateString(data.duedate), marginX, YpositionLeft(0.2));
    } else {
        doc.text("Valid Till # " + formatDateString(data.duedate), marginX, YpositionLeft(0.2));
    }

    // Add logo (assuming you have the logo as a base64 string or url)

    const getLogoImagePosition = (imgw) => {
        let temp = 1.5 - imgw;
        return 6.08 + (temp / 2)
    }

    if (data.logoimage.imageSrc) {
        const logoheight = data.logoimage.imageHeight > data.logoimage.imageWidth;
        const square = data.logoimage.imageHeight === data.logoimage.imageWidth;
        const limageh = data.logoimage.imageHeight;
        const limagew = data.logoimage.imageWidth;


        if (!square) {


            if (!logoheight) {  // Width is long
                let temw = 1.5
                let temh = (temw / limagew) * limageh;

                if (temh > 1) {
                    const finalh = 1;
                    const finalw = (finalh / temh) * temw;
                    doc.addImage(data.logoimage.imageSrc, 'PNG', getLogoImagePosition(finalw), YpositionRight(marginY - 0.1), finalw, finalh);

                }
                else {
                    doc.addImage(data.logoimage.imageSrc, 'PNG', 6.08, YpositionRight(marginY - 0.1), 1.5, 0);
                }
            }
            else {   // Height is long
                let temh = 1;
                let temw = (temh / limageh) * limagew
                doc.addImage(data.logoimage.imageSrc, 'PNG', getLogoImagePosition(temw), YpositionRight(marginY - 0.1), 0, 1);
            }
            // doc.addImage(data.logoimage.imageSrc, 'PNG', 6, YpositionRight(marginY - 0.1), logoheight ? 0 : 1.5, logoheight ? 1 : 0); // 2.5:1 ratio

        }

        else {  //if square
            doc.addImage(data.logoimage.imageSrc, 'PNG', 6.58, YpositionRight(marginY - 0.1), 1, 1); // 2.5:1 ratio

        }
    }


    doc.setFontSize(14);

    if (data.name === 'Invoice') {
        doc.text("Billed to", marginX + 0.05, YpositionLeft(0.5))
        doc.text("Billed From", 4.3, YpositionLeft())
    }
    else {
        doc.text("Quotation to", marginX + 0.05, YpositionLeft(0.5))
        doc.text("Quotation From", 4.3, YpositionLeft())
    }

    doc.setFontSize(10);

    const headerColumn1 = [data.billTo.clientname]; //Name if client or business 

    let headerRow1 = [[data.billTo.address], [data.billTo.addressline2], [data.billTo.emailphone]];
    let headerAltrenateRow1 = [[data.billTo.address], [data.billTo.emailphone]];

    if (data.billTo.gstno != '') {
        headerRow1 = [...headerRow1, ["GST: " + data.billTo.gstno]]
        headerAltrenateRow1 = [...headerAltrenateRow1, ["GST: " + data.billTo.gstno]]
    }



    doc.autoTable({
        startY: YpositionLeft(0.15),
        head: [headerColumn1],
        body: data.billTo.addressline2 != '' ? headerRow1 : headerAltrenateRow1,
        margin: { right: 4.3 },
        styles: {
            fillColor: [255, 255, 255], // Cell fill color
            textColor: [0, 0, 0], // Cell text color
            fontSize: 10,
            halign: 'left',
            valign: 'middle', // Cell vertical alignment
            lineWidth: 0.01, // Border width
            lineColor: [209, 209, 209], // Border color

            cellPadding: 0.05, // Reduce cell padding


        },
        headStyles: {
            fillColor: [255, 255, 255], // Header fill color
            textColor: [0, 0, 0], // Header text color
            fontSize: 11,
            fontStyle: 'bold', // Header text style
        },
        didParseCell: (data) => {
            data.cell.styles.fillColor = [255, 255, 255]; // Set the fill color to white for each cell
        },
    });

    let billingTableHeight = doc.previousAutoTable.finalY - YpositionLeft();  // This is used to count the height of current table and will be compare with the next table height.


    const headerColumn2 = [data.billFrom.businessname]; //Name if client or business 

    let headerRow2 = [[data.billFrom.address], [data.billFrom.addressline2], [data.billFrom.emailphone]];
    let headerAltrenateRow2 = [[data.billFrom.address], [data.billFrom.emailphone]];

    if (data.billFrom.gstno != '') {
        headerRow2 = [...headerRow2, ["GST: " + data.billFrom.gstno]]
        headerAltrenateRow2 = [...headerAltrenateRow2, ["GST: " + data.billFrom.gstno]]
    }

    doc.autoTable({
        startY: YpositionLeft(),
        head: [headerColumn2],
        body: data.billFrom.addressline2 != '' ? headerRow2 : headerAltrenateRow2,
        margin: { left: 4.3 },
        styles: {
            fillColor: [255, 255, 255], // Cell fill color
            textColor: [0, 0, 0], // Cell text color
            fontSize: 10,
            halign: 'left',
            valign: 'middle', // Cell vertical alignment
            lineWidth: 0.01, // Border width
            lineColor: [209, 209, 209], // Border color

            cellPadding: 0.05, // Reduce cell padding


        },
        headStyles: {
            fillColor: [255, 255, 255], // Header fill color
            textColor: [0, 0, 0], // Header text color
            fontSize: 11,
            fontStyle: 'bold', // Header text style
        },
        didParseCell: (data) => {
            data.cell.styles.fillColor = [255, 255, 255]; // Set the fill color to white for each cell
        },
    });


    let tableHeight = (doc.previousAutoTable.finalY - YpositionLeft()) > billingTableHeight ? (doc.previousAutoTable.finalY - YpositionLeft()) : billingTableHeight;


    doc.autoTable({
        startY: YpositionLeft(tableHeight + 0.2),
        head: [data.column],
        body: data.row,
        margin: { bottom: 1.2 },

        styles: {
            fillColor: [255, 255, 255], // Cell fill color
            textColor: [0, 0, 0], // Cell text color
            fontSize: 9,
            valign: 'middle', // Cell vertical alignment
            lineWidth: 0.01, // Border width
            lineColor: [110, 110, 110] // Border color
        },
        headStyles: {
            fillColor: [255, 255, 255], // Header fill color
            textColor: [0, 0, 0], // Header text color
            fontSize: 10,
            fontStyle: 'bold', // Header text style
        }

    });


    //Some secret calculation 
    tableHeight = doc.previousAutoTable.finalY - YpositionLeft();
    let finallY = doc.previousAutoTable.finalY;
    const marginBottom = 0.5;
    const bottomspace = data.gsttype === 'igst' ? 3.5 : 3.6;
    const pageHeighttemp = doc.internal.pageSize.height;
    if (finallY + bottomspace > pageHeighttemp - marginBottom) {
        doc.addPage();
        YpositionLeft(1, true);
        console.log(YpositionLeft());
    }
    else {
        YpositionLeft(tableHeight + 0.5)
    }


    // Final Calculation
    let amountCol = [];
    if (data.gsttype === 'igst') {
        amountCol = [['Amount', formatIndianCurrency(data.total.overallrate)], ['IGST', formatIndianCurrency(data.total.overalltax)]]
    }
    else {
        amountCol = [['Amount', formatIndianCurrency(data.total.overallrate)], ['CGST', formatIndianCurrency(data.total.overalltax / 2)], ['SGST', formatIndianCurrency(data.total.overalltax / 2)]]
    }


    doc.autoTable({
        startY: YpositionLeft(),
        head: amountCol,

        margin: { left: 5 },

        styles: {
            fillColor: [255, 255, 255], // Cell fill color
            textColor: [0, 0, 0], // Cell text color
            fontSize: 11,
            halign: 'right',
            valign: 'middle', // Cell vertical alignment
            lineWidth: 0.01, // Border width
            lineColor: [255, 255, 255], // Border color

        },
        headStyles: {
            fillColor: [255, 255, 255], // Header fill color
            textColor: [0, 0, 0], // Header text color
            fontSize: 11,
            fontStyle: 'normal', // Header text style
        }


    });

    tableHeight = doc.previousAutoTable.finalY - YpositionLeft();


    if (data.bankdetail.accountholdername !== '') {
        let bankhead = [['A/C Holder Name :', data.bankdetail.accountholdername], ['Account Number :', data.bankdetail.accountnumber]];
        bankhead = [...bankhead, ['Account IFSC :', data.bankdetail.accountifsc], ['Bank Name :', data.bankdetail.bankname]]

        doc.setFont('Helvetica', 'bold');
        doc.setFontSize(12)
        doc.text("Bank Details ", marginX + 0.05, YpositionLeftTemp(YpositionLeft()));
        doc.setFont('Helvetica', 'normal');
        doc.setFontSize(11)
        //Bank Detail Section
        doc.autoTable({
            startY: YpositionLeftTemp(0.12),
            body: bankhead,

            margin: { right: 3.6, left: 0.5 },

            styles: {
                fillColor: [255, 255, 255], // Cell fill color
                textColor: [0, 0, 0], // Cell text color
                fontSize: 11,
                halign: 'left',
                valign: 'middle', // Cell vertical alignment
                lineWidth: 0.01, // Border width
                lineColor: [255, 255, 255], // Border color
                cellPadding: 0.08, // Reduce cell padding


            },
            columnStyles: {
                0: { fontStyle: 'bold', cellWidth: 1.5 }, // Make the first column bold

            },
            headStyles: {
                fillColor: [255, 255, 255], // Header fill color
                textColor: [0, 0, 0], // Header text color
                fontSize: 11,
                fontStyle: 'normal', // Header text style
            },
            didParseCell: (data) => {
                data.cell.styles.fillColor = [255, 255, 255]; // Set the fill color to white for each cell
            },
        });

    }
    let bankTableHeight = doc.previousAutoTable.finalY - YpositionLeftTemp();


    doc.setLineWidth(0.01); // Set line width
    doc.setDrawColor(179, 179, 179);
    doc.line(5.3, YpositionLeft(tableHeight + 0.1), 7.8, YpositionLeft())




    const TotalAmountWithGST = ['Total', formatIndianCurrency(data.total.overallamount)]

    doc.autoTable({
        startY: YpositionLeft(0.1),
        head: [TotalAmountWithGST],

        margin: { left: 5.2 },

        styles: {
            fillColor: [255, 255, 255], // Cell fill color
            textColor: [0, 0, 0], // Cell text color
            fontSize: 11,
            halign: 'right',
            valign: 'middle', // Cell vertical alignment
            lineWidth: 0.01, // Border width
            lineColor: [255, 255, 255], // Border color

        },
        headStyles: {
            fillColor: [255, 255, 255], // Header fill color
            textColor: [0, 0, 0], // Header text color
            fontSize: 12,
            fontStyle: 'bold', // Header text style
        }


    });

    const getImagePositon = (imgWidth) => {
        let spaceLeft = 2.5 - imgWidth;
        return 5.28 + (spaceLeft / 2)
    }


    const signatureheight = data.signatureimage.imageHeight > data.signatureimage.imageWidth;
    const square2 = data.signatureimage.imageHeight === data.signatureimage.imageWidth;

    let globalfinalh = 0;


    if (data.signatureimage.imageSrc) {

        if (!square2) {  // Not Square Image
            const imgh = data.signatureimage.imageHeight;
            const imgw = data.signatureimage.imageWidth;

            if (!signatureheight) {
                let temw = 2.5;
                let temh = (temw / imgw) * imgh;

                if (temh > 1.5) {
                    const finalh = 1.5;
                    const finalw = (finalh / temh) * temw;
                    globalfinalh = finalh;

                    doc.addImage(data.signatureimage.imageSrc, 'PNG', getImagePositon(finalw), YpositionLeft(0.4), finalw, finalh); // 2.5:1 ratio
                }
                else {
                    doc.addImage(data.signatureimage.imageSrc, 'PNG', 5.28, YpositionLeft(0.4), 2.5, 0); // 2.5:1 ratio
                    globalfinalh = temh;
                }
            }
            else {
                let temh = 1.5;
                let temw = (temh / imgh) * imgw;
                globalfinalh = temh;
                doc.addImage(data.signatureimage.imageSrc, 'PNG', getImagePositon(temw), YpositionLeft(0.4), 0, temh); // 2.5:1 ratio
            }



        }
        else {  //Square Image
            doc.addImage(data.signatureimage.imageSrc, 'PNG', 6, YpositionLeft(0.4), 1.2, 1.2); // 2.5:1 ratio
            globalfinalh = 1.2;
        }
    }
    else {
        YpositionLeft(0.5)
    }


    doc.setFont('Helvetica', 'bold');

    if (data.signatureimage.imageSrc) {
        if (square2) {
            doc.text('Authorized Signatory', 5.85, YpositionLeft(1.2 + 0.1))

        }
        else {
            if (signatureheight) {
                doc.text('Authorized Signatory', 5.85, YpositionLeft(globalfinalh + 0.2))

            }
            else {

                doc.text('Authorized Signatory', 5.85, YpositionLeft(globalfinalh + 0.2))

            }
        }
    }


    const contentData = data.notes;

    tableHeight = doc.previousAutoTable.finalY - YpositionLeft();

    const notesHead = 'Notes / Terms :'
    const additionalNotes = [contentData];
    if (contentData != '') {
        doc.autoTable({
            startY: YpositionLeftTemp(bankTableHeight + 0.2),
            head: [{ notesHead }],
            body: [additionalNotes],

            margin: { right: 3.6, left: marginX - 0.05 },

            styles: {
                fillColor: [255, 255, 255], // Cell fill color
                textColor: [0, 0, 0], // Cell text color
                fontSize: 11,
                halign: 'left',
                // Cell vertical alignment
                lineWidth: 0.01, // Border width
                lineColor: [255, 255, 255], // Border color

            },
            headStyles: {
                fillColor: [255, 255, 255], // Header fill color
                textColor: [0, 0, 0], // Header text color
                fontSize: 11,
                fontStyle: 'bold', // Header text style
            }


        });
    }


    doc.setTextColor(0, 0, 0)





    // Function to add the footer

    const pageCount = doc.internal.getNumberOfPages();
    const footerText = 'Page ';
    const footerY = doc.internal.pageSize.height - 0.5; // 0.5 inches = 36 points
    const footerX = doc.internal.pageSize.width - 0.5;





    for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);

        //  doc.line(marginX, footerY, footerX, footerY);
        doc.setFont('Helvetica', 'bold');
        doc.setFontSize(9);
        doc.setLineDashPattern([0.05, 0.05], 0);
        doc.setDrawColor(179, 179, 179);
        doc.setLineWidth(0.01); // Set consistent line width
        doc.line(marginX, footerY, footerX, footerY);
        doc.text(footerText + i + ' of ' + pageCount, doc.internal.pageSize.width / 1.2, footerY + 0.23, { align: 'center' });
        doc.text(data.name + " No # " + data.invoiceno, marginX + 1, footerY + 0.23, { align: 'center' });

    }







    {/* ------------------------------------------------------ Editable Section */ }

    doc.save(data.name + '-' + data.billTo.clientname + '-' + data.invoiceno + '-' + data.date)

}



