import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react'
import '../CSS/Theme2.css'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Stack } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import AlertBox from '../components/AlertBox';
import uploadLogo from '../logo/Upload Logo.png'
import signatureLogo from '../logo/Upload Signature.png'
import { getMyPDF } from '../components/GetMyPDF'
import { Helmet } from 'react-helmet';



const Theme2 = forwardRef((props, ref) => {
    {/*-------------------------------------------------------------------------------- Variable/Constant Section --------------------------------------------------------------------------------------------------------------------------------------- */ }




    {/* ****************************************************************************** End VAriable/Constant Section ************************************************************************************************************************************* */ }


    {/*-------------------------------------------------------------------------------- State Section --------------------------------------------------------------------------------------------------------------------------------------- */ }
    {/* Main Items collection (Table Content / User data ) */ }
    const [mainItems, SetMainItems] = useState([{ id: 1, itemname: '', itemdesc: '', taxpercent: 0, quantity: 0, rate: 0, amount: 0, taxamount: 0, totalrate: 0, validateitemname: true, validatetax: true, validatequantity: true, validaterate: true }]);

    {/* This state is used for storing the logo image */ }
    const [selectedImage, setSelectedImage] = useState(null)
    const [selectedSignatureImage, setSelectedSignatureImage] = useState(null)
    const fileInputRef = useRef(null);
    const fileSignatureInputRef = useRef(null);

    let [isRemoved, SetRemoved] = useState(0);
    let [itemId, setItemId] = useState(2);
    let [overAllTotal, setOverallTotal] = useState({ overalltax: 0, overallamount: 0, overallrate: 0 });   // This is the data to be send 

    let [Date, setDate] = useState({ invoicedate: '', duedate: '' });  // This is the data to be send 
    let [validateDate, SetDateValidate] = useState({ invoicedate: true, duedate: true })

    let [invoiceNo, SetInvoiceNo] = useState("");  // This is the data to be send 
    let [billTo, SetBillTo] = useState({ clientname: '', address: '', addressline2: '', emailphone: '', gstno: '' })  // This is the data to be send 
    let [billFrom, SetBillFrom] = useState({ businessname: '', address: '', addressline2: '', emailphone: '', gstno: '' })  // This is the data to be send 
    let [notes, setNotes] = useState('');  // This is the data to be send 

    let [LogoImage, SetLogoImage] = useState({ imageSrc: null, imageHeight: 0, imageWidth: 0 });  // This is the data to be send 
    let [SignatureImage, SetSignatureImage] = useState({ imageSrc: null, imageHeight: 0, imageWidth: 0 });  // This is the data to be send 




    let [isBankCheck, SetBankChecked] = useState(false);


    let [bankDetails, SetBankDetails] = useState({ accountholdername: '', accountnumber: '', accountifsc: '', bankname: '', state: 0 });  // This is the data to be send 
    let [bankValidate, SetBankValidate] = useState({ accountholdername: true, accountnumber: true, accountifsc: true, bankname: true })

    const [inputBorderColor, SetInputBorderColor] = useState(false);

    const [modalstate, SetModalState] = useState(false);

    //Validation states

    let [billToValidate, SetBillToValidate] = useState({ clientname: true, address: true, addressline2: true, emailphone: true, gstno: true })
    let [billFromValidate, SetBillFromValidate] = useState({ businessname: true, address: true, addressline2: true, emailphone: true, gstno: true })

    const [gstType, setGstType] = useState('igst');



    {/* ****************************************************************************** End State Section ************************************************************************************************************************************* */ }

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);



    {/*-------------------------------------------------------------------------------- Side Effects Section --------------------------------------------------------------------------------------------------------------------------------------- */ }

    useEffect(() => {
        handleItemOnChange(null, null, null);

    }, [isRemoved])

    {/* ****************************************************************************** End Side Effects Section ************************************************************************************************************************************* */ }



    {/*-------------------------------------------------------------------------------- function Section --------------------------------------------------------------------------------------------------------------------------------------- */ }

    const roundToTwoDecimals = (num) => {
        return Math.round(num * 100) / 100;
    };


    const handleImageClick = () => {
        fileInputRef.current.click();
    };

    const handleSignatureImageClick = () => {
        fileSignatureInputRef.current.click();
    };



    {/* function to handle all the major calculations and input field updation */ }
    const handleItemOnChange = (id, newValue, colname) => {

        let totalAmount = 0;
        let totalTaxAmount = 0;
        let totalRate = 0;

        const CalculateFinalAmount = (obj) => {    // Per item calculation
            let mTotalRate = roundToTwoDecimals(obj.quantity * obj.rate)
            let mTotalTax = roundToTwoDecimals((mTotalRate * obj.taxpercent) / 100);
            let mTotalAmount = roundToTwoDecimals(mTotalRate + mTotalTax);
            obj = { ...obj, amount: mTotalAmount, taxamount: mTotalTax, totalrate: mTotalRate }
            OverallCalculation(obj);
            return obj;

        }

        const OverallCalculation = (obj) => {  // data is added each for each item.
            totalAmount = roundToTwoDecimals(totalAmount + obj.amount);
            totalTaxAmount = roundToTwoDecimals(totalTaxAmount + obj.taxamount);
            totalRate = roundToTwoDecimals(totalRate + obj.totalrate);

        }


        let updatedItem = mainItems.map((item) => {

            if (item.id === id) {
                let obj;

                switch (colname) {
                    case 'itemname': obj = { ...item, itemname: newValue }
                        obj = CalculateFinalAmount(obj);
                        return obj;
                        break;

                    case 'taxpercent': obj = { ...item, taxpercent: newValue }
                        obj = CalculateFinalAmount(obj);
                        return obj;
                        break;

                    case 'quantity': obj = { ...item, quantity: newValue }
                        obj = CalculateFinalAmount(obj);
                        return obj;

                        break;

                    case 'rate': obj = { ...item, rate: newValue }
                        obj = CalculateFinalAmount(obj);
                        return obj;
                        break;
                    case 'itemdesc': obj = { ...item, itemdesc: newValue }
                        obj = CalculateFinalAmount(obj);
                        return obj;
                        break;

                }
            }

            else {
                CalculateFinalAmount(item);
            }



            return item;  // default return
        });


        setOverallTotal({ ...overAllTotal, overalltax: totalTaxAmount, overallrate: totalRate, overallamount: totalAmount });

        SetMainItems(updatedItem);
    }

    {/* Function to add new items to the mainItems collection */ }
    const addItems = () => {
        setItemId(itemId + 1);
        const fItem = {
            id: itemId, itemname: '', itemdesc: '', taxpercent: 0, quantity: 0, rate: 0, amount: 0, taxamount: 0, totalrate: 0, validateitemname: true, validatetax: true, validatequantity: true, validaterate: true
        }

        SetMainItems([...mainItems, fItem]);

    }


    const removeItem = async (indexToRemove) => {
        if (mainItems.length > 1) {
            SetMainItems(mainItems.filter((item, index) => { return index !== indexToRemove }));
            SetRemoved(isRemoved + 1);
        }

    };


    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
            const reader = new FileReader();
            reader.onload = function (e) {

                setSelectedImage(e.target.result);
                const img = new Image();
                img.onload = () => {
                    SetLogoImage({ imageSrc: img.src, imageHeight: img.naturalHeight, imageWidth: img.naturalWidth })
                };
                img.src = e.target.result;



            };
            reader.readAsDataURL(file);
            event.target.value = null;
        } else {
            setSelectedImage(null);
            event.target.value = null;
        }
    };

    const handleSignatureImageChange = (event) => {
        const file = event.target.files[0];
        if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
            const reader = new FileReader();
            reader.onload = function (e) {

                setSelectedSignatureImage(e.target.result);

                const img = new Image();

                img.onload = () => {
                    SetSignatureImage({ imageSrc: img.src, imageHeight: img.naturalHeight, imageWidth: img.naturalWidth })
                };

                img.src = e.target.result;

            };
            reader.readAsDataURL(file);
            event.target.value = null;
        } else {
            setSelectedSignatureImage(null);
            event.target.value = null;
        }
    };



    const removeLogo = () => {

        setSelectedImage(null);
        SetLogoImage({ imageSrc: null, imageHeight: 0, imageWidth: 0 });

    }


    const handleDateChange = (e, type) => {
        switch (type) {
            case "invoicedate": setDate({ ...Date, invoicedate: e.target.value })
                break;
            case "duedate": setDate({ ...Date, duedate: e.target.value })
                break;
        }
    }


    const handleSetBillToInfo = (e, type) => {
        switch (type) {
            case "clientname": SetBillTo({ ...billTo, clientname: e.target.value })
                break;
            case "address": SetBillTo({ ...billTo, address: e.target.value })
                break;
            case "addressline2": SetBillTo({ ...billTo, addressline2: e.target.value })
                break;
            case "emailphone": SetBillTo({ ...billTo, emailphone: e.target.value })
                break;
            case "gstno": SetBillTo({ ...billTo, gstno: e.target.value.toUpperCase() })
                break;
        }
    }

    const handleSetBillFromInfo = (e, type) => {
        switch (type) {
            case "businessname": SetBillFrom({ ...billFrom, businessname: e.target.value })
                break;
            case "address": SetBillFrom({ ...billFrom, address: e.target.value })
                break;
            case "addressline2": SetBillFrom({ ...billFrom, addressline2: e.target.value })
                break;
            case "emailphone": SetBillFrom({ ...billFrom, emailphone: e.target.value })
                break;
            case "gstno": SetBillFrom({ ...billFrom, gstno: e.target.value.toUpperCase() })
                break;
        }
    }


    const GetAllData = () => {

        // useState([{ id: 1, ITEM_NAME: '', itemdesc: '', taxpercent: 0, quantity: 0, rate: 0, amount: 0, taxamount: 0, totalrate: 0 }]);
        const columns = ["#", "Item name", "GST%", "Quantity", "Rate", "Tax Amount", "Amount", "Total"];
        let rows = [];

        mainItems.map((item, index) => {

            const desc = item.itemdesc && '\n(' + item.itemdesc.trim() + ')';
            rows = [...rows, [index + 1, item.itemname.trim() + desc, item.taxpercent, item.quantity, item.rate, item.taxamount, item.totalrate, item.amount]];
        });

        {/* 
           let [bankDetails, SetBankDetails] = useState({ accountholdername: '', accountnumber: '', accountifsc: '', bankname: '', state: 0 });
     */}
        const trimedBillTo = { ...billTo, clientname: billTo.clientname.trim(), address: billTo.address.trim(), addressline2: billTo.addressline2.trim(), emailphone: billTo.emailphone.trim() };

        const trimedBillFrom = { ...billFrom, businessname: billFrom.businessname.trim(), address: billFrom.address.trim(), addressline2: billFrom.addressline2.trim(), emailphone: billFrom.emailphone.trim() };

        const trimedNotes = notes.trim();

        const trimedBankDetails = { ...bankDetails, accountholdername: bankDetails.accountholdername.trim(), accountnumber: bankDetails.accountnumber.trim(), accountifsc: bankDetails.accountifsc.trim(), bankname: bankDetails.bankname.trim() };

        const data = {
            date: Date.invoicedate,
            duedate: Date.duedate,
            invoiceno: invoiceNo,
            billTo: trimedBillTo,
            billFrom: trimedBillFrom,
            notes: trimedNotes,
            row: rows,
            column: columns,
            total: overAllTotal,
            logoimage: LogoImage,
            signatureimage: SignatureImage,
            bankdetail: trimedBankDetails,
            gsttype: gstType,
            name: "Invoice"
        }


        return data;
    }


    //function to check and verify all the data before uploading it to the PDF generation
    const VerifyData = () => {

        let valid = true;
        // Bill To Validation +++++++++++++++++++++++++++++++++++
        const temBillTo = ({ ...billToValidate });
        if (billTo.clientname === '') {
            temBillTo.clientname = false;
            valid = false;
        }
        else {
            temBillTo.clientname = true;

        }

        //Address
        if (billTo.address === '') {
            temBillTo.address = false;
            valid = false;
        }
        else {
            temBillTo.address = true;
        }

        // Email / Phone
        if (billTo.emailphone === '') {
            temBillTo.emailphone = false;
            valid = false;
        }
        else {
            temBillTo.emailphone = true;
        }

        SetBillToValidate(temBillTo)



        // Bill From Validation +++++++++++++++++++++++++++++++++++


        const temBillFrom = ({ ...billFromValidate });
        // Business Name
        if (billFrom.businessname === '') {
            temBillFrom.businessname = false
            valid = false;

        }
        else {
            temBillFrom.businessname = true

        }

        // Address
        if (billFrom.address === '') {
            temBillFrom.address = false
            valid = false;

        }
        else {
            temBillFrom.address = true

        }

        //Email / Phone
        if (billFrom.emailphone === '') {
            temBillFrom.emailphone = false
            valid = false;

        }
        else {
            temBillFrom.emailphone = true

        }

        SetBillFromValidate(temBillFrom)

        {/*

     const [mainItems, SetMainItems] = useState([{ id: 1, ITEM_NAME: '', itemdesc: '', taxpercent: 0, quantity: 0, rate: 0, amount: 0, taxamount: 0, totalrate: 0, validateitemname: true, validatetax:true,validatequantity:true,validaterate:true }]);
            */}



        const updatedItems = mainItems.map((item) => {
            let obj = ({ ...item })
            if (item.itemname === '') {
                obj.validateitemname = false;
                valid = false;
            }
            else {
                obj.validateitemname = true;
            }


            if (item.taxpercent < 0) {
                obj.validatetax = false;
                valid = false;
            }
            else {
                obj.validatetax = true;
            }


            if (item.quantity <= 0) {
                obj.validatequantity = false;
                valid = false;
            }
            else {
                obj.validatequantity = true;
            }


            if (item.rate <= 0) {
                obj.validaterate = false;
                valid = false;
            }
            else {
                obj.validaterate = true;
            }

            return obj;


        });

        SetMainItems(updatedItems)


        //Bank Details verification 

        if (isBankCheck) {
            const obj = { ...bankValidate }
            if (bankDetails.accountholdername === '') {
                obj.accountholdername = false
                valid = false;
            }
            else {
                obj.accountholdername = true
            }

            if (bankDetails.accountnumber === '') {
                obj.accountnumber = false
                valid = false;
            }
            else {
                obj.accountnumber = true
            }

            if (bankDetails.accountifsc === '') {
                obj.accountifsc = false
                valid = false;
            }
            else {
                obj.accountifsc = true
            }

            if (bankDetails.bankname === '') {
                obj.bankname = false
                valid = false;
            }
            else {
                obj.bankname = true
            }

            SetBankValidate(obj);

        }

        //Date Check 
        const temdate = { ...validateDate }
        if (Date.invoicedate.trim() === '') {
            valid = false;
            temdate.invoicedate = false;

        }
        else {
            temdate.invoicedate = true

        }

        if (Date.duedate.trim() === '') {
            valid = false;
            temdate.duedate = false;

        }
        else {
            temdate.duedate = true;
        }

        SetDateValidate(temdate);

        SetModalState(!valid);

        return valid;
    }

    const navigate = useNavigate();
    const GeneratePDFFromData = () => {

        if (VerifyData()) {

            const data = GetAllData();
            getMyPDF(data)
            // navigate('/test', { state: data });
        }

    }


    const handleBankToggle = (event) => {
        SetBankChecked(event.target.checked)
        if (!event.target.checked) {
            SetBankDetails({ accountholdername: '', accountnumber: '', accountifsc: '', bankname: '', state: 0 })
        }

    }


    const handleBankDetails = (event, type) => {

        switch (type) {
            case 'accountholdername': SetBankDetails({ ...bankDetails, accountholdername: event.target.value })
                break;
            case 'accountnumber': SetBankDetails({ ...bankDetails, accountnumber: event.target.value })
                break;
            case 'accountifsc': SetBankDetails({ ...bankDetails, accountifsc: event.target.value })
                break;
            case 'bankname': SetBankDetails({ ...bankDetails, bankname: event.target.value })
                break;
        }

    }


    const handleGstTypeChange = (event) => {

        setGstType(event.target.value);

        console.log(event.target.value)
    }



    // This section is controller by the outer world ------------------(((((((0)))))))
    const resetEverything = () => {

        //clearing Date
        SetMainItems([{ id: 1, itemname: '', itemdesc: '', taxpercent: 0, quantity: 0, rate: 0, amount: 0, taxamount: 0, totalrate: 0, validateitemname: true, validatetax: true, validatequantity: true, validaterate: true }]);
        setDate({ invoicedate: '', duedate: '' })
        SetBillTo({ clientname: '', address: '', addressline2: '', emailphone: '', gstno: '' });
        SetBillFrom({ businessname: '', address: '', addressline2: '', emailphone: '', gstno: '' })
        setNotes('');
        SetLogoImage({ imageSrc: null, imageHeight: 0, imageWidth: 0 });
        SetSignatureImage({ imageSrc: null, imageHeight: 0, imageWidth: 0 });
        SetBankDetails({ accountholdername: '', accountnumber: '', accountifsc: '', bankname: '', state: 0 })
        SetBankChecked(false);
        setSelectedImage(null);
        setSelectedSignatureImage(null)
        SetInvoiceNo(1);


    };

    useImperativeHandle(ref, () => ({
        resetEverything
    }));
    // This section is controller by the outer world ------------------(((((((0)))))))

    {/* ****************************************************************************** End Function Section ************************************************************************************************************************************* */ }

    return (
        <div>
            <div className='element2'>
                <Row className='row-r1'>
                    <Col>
                        <Stack direction='vertical'>
                            <h1 className='mb-3'>Invoice</h1>
                            <input
                                type="file"
                                onChange={handleImageChange}
                                accept="image/png, image/jpeg"
                                style={{ display: 'none' }}
                                ref={fileInputRef}
                                multiple={false}
                            />
                            <div className="image-wrapper" >
                                <img
                                    className='logo-image'
                                    src={selectedImage || uploadLogo}
                                    alt="Placeholder Image"
                                    onClick={handleImageClick}

                                />
                            </div>

                            <button className='remove-button' style={{ display: selectedImage ? 'block' : 'none', maxWidth: '180px' }} onClick={removeLogo}>Remove logo</button>
                        </Stack>



                    </Col>
                    <Col>

                    </Col>
                </Row>

                <Row className='row-r1'>
                    <Col md={6} className='col-c1'>
                        <Stack direction='vertical'>
                            <Stack className='stack-1' direction='horizontal'> <Stack direction='vertical'><h6 className='p-date-name '>Invoice Date:</h6> <input type='date' value={Date.invoicedate} onChange={e => handleDateChange(e, "invoicedate")} className={`input-date ${validateDate.invoicedate ? '' : 'custom-border'}`} /></Stack></Stack>
                            <Stack className='stack-2' direction='horizontal'><Stack direction='vertical'><h6 className='p-date-name '>Due Date:</h6> <input value={Date.duedate} onChange={e => handleDateChange(e, "duedate")} type='date' className={`input-due-date ${validateDate.duedate ? '' : 'custom-border'}`} /></Stack></Stack>
                        </Stack>

                    </Col>
                    <Col md={6} className='col-c2'><Stack direction='vertical'><h6 >Invoice Number:</h6> <input type='text' value={invoiceNo} onChange={e => { SetInvoiceNo(e.target.value.toUpperCase()) }} min="0" placeholder='1' className='p-invoice-number' /></Stack></Col>
                </Row>

                <div className='divider'></div>

                <Row className='row-r1'>
                    <Col md={6}>
                        <div >
                            <h6 className='h6-modified'>Bill to:</h6>
                            <input value={billTo.clientname} onChange={e => { handleSetBillToInfo(e, "clientname") }} type='text' className={` input-template ${billToValidate.clientname ? '' : 'custom-border'}`} placeholder='Who is this invoice to? (Client/Business name)' maxLength={100} />
                            <input value={billTo.address} onChange={e => { handleSetBillToInfo(e, "address") }} type='text' className={` input-template ${billToValidate.address ? '' : 'custom-border'}`} placeholder='Billing address' maxLength={100} />
                            <input value={billTo.addressline2} onChange={e => { handleSetBillToInfo(e, "addressline2") }} type='text' className=" input-template" placeholder='Address line 2' maxLength={100} />
                            <input value={billTo.emailphone} onChange={e => { handleSetBillToInfo(e, "emailphone") }} type='text' className={`input-template ${billToValidate.emailphone ? '' : 'custom-border'}`} placeholder='Email/Phone' maxLength={100} />
                            <input value={billTo.gstno} onChange={e => { handleSetBillToInfo(e, "gstno") }} type='text' className=" input-template" placeholder='GST No.' maxLength={15} minLength={15} />
                        </div>
                    </Col>
                    <Col md={6} >
                        <div className='div-billfrom' >
                            <h6 className='h6-modified'>Bill from:</h6>
                            <input value={billFrom.businessname} onChange={e => { handleSetBillFromInfo(e, "businessname") }} type='text' className={` input-template ${billFromValidate.businessname ? '' : 'custom-border'}`} placeholder='Who is this invoice from? (Business Name)' maxLength={100} />
                            <input value={billFrom.address} onChange={e => { handleSetBillFromInfo(e, "address") }} type='text' className={` input-template ${billFromValidate.address ? '' : 'custom-border'}`} placeholder='Business address' maxLength={100} />
                            <input value={billFrom.addressline2} onChange={e => { handleSetBillFromInfo(e, "addressline2") }} type='text' className=" input-template" placeholder='Address line 2' maxLength={100} />
                            <input value={billFrom.emailphone} onChange={e => { handleSetBillFromInfo(e, "emailphone") }} type='text' className={` input-template ${billFromValidate.emailphone ? '' : 'custom-border'}`} placeholder='Email/Phone' maxLength={100} />
                            <input value={billFrom.gstno} onChange={e => { handleSetBillFromInfo(e, "gstno") }} type='text' className=" input-template" placeholder='GST No.' maxLength={15} minLength={15} />
                        </div>
                    </Col>
                </Row>

                <div className='line-div'>
                    <Row className='row-r2' >
                        <Col xs={3} ><h6 className='font-h6'>Item name</h6></Col> <Col> <h6 className='font-h6'>GST %</h6></Col> <Col><h6 className='font-h6'>Quantity</h6></Col> <Col><h6 className='font-h6'>Rate</h6></Col> <Col xs={3} ><h6 className='font-h6'>Amount</h6></Col>
                    </Row>
                </div>


                {/* Item table  react map function is utilize to display all the items  */}
                {mainItems.map((item, index) => (
                    <div key={index}>
                        <Row className='row-r3'>
                            <Col xs={3}><input type='text' value={item.itemname === 0 ? '' : item.itemname} onChange={e => { handleItemOnChange(item.id, e.target.value, "itemname") }} className={`input-template ${item.validateitemname ? '' : 'custom-border'} input-field `} placeholder='Item name' /></Col>{/* Input itemname */}
                            <Col><input value={item.taxpercent === 0 ? '' : item.taxpercent} onChange={e => { handleItemOnChange(item.id, e.target.value, "taxpercent") }} type='number' min='0' className={`input-template ${item.validatetax ? '' : 'custom-border'} input-field `} placeholder='%' /></Col>{/* Input taxpercent */}
                            <Col><input value={item.quantity === 0 ? '' : item.quantity} onChange={e => { handleItemOnChange(item.id, e.target.value, "quantity") }} type='number' min='0' className={`input-template ${item.validatequantity ? '' : 'custom-border'} input-field `} placeholder='0' /></Col>{/* Input quantity */}
                            <Col><input value={item.rate === 0 ? '' : item.rate} onChange={e => { handleItemOnChange(item.id, e.target.value, "rate") }} type='number' min='0' className={`input-template ${item.validaterate ? '' : 'custom-border'} input-field `} placeholder='0' /></Col> {/* Input Rate */}
                            <Col xs={3}><input type='text' value={item.amount === 0 ? '' : item.amount} disabled readOnly className='input-template input-field' placeholder='0' /></Col>
                        </Row>
                        <Row className='row-r3' >
                            <Col xs={5} ><input type='text' value={item.itemdesc} onChange={e => { handleItemOnChange(item.id, e.target.value, "itemdesc") }} className='input-template input-field' placeholder='Item description' /></Col>  <Col></Col> <Col></Col> <Col></Col>{/* Input Desc */}

                            <Col xs={3} >{mainItems.length > 1 ? <button onClick={() => { removeItem(index) }} className='remove-button'> {/* Remove Button */}
                                <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z" />
                                </svg>

                            </button> : null}</Col>
                        </Row>

                        <div className='divider'></div>
                    </div>
                ))}







                <button onClick={() => { addItems() }} className='add-button'> Add item </button>

                <div >  {/* Division holding The Calculation Section */}

                    <Row className='row-r3' >
                        <Col></Col> {/* First Section */}

                        <Col>  {/* Second Section */}
                            <Row>
                                <Col className='col-subtotal-h2' ><h6>Subtotal:</h6></Col>
                                <Col className='col-subtotal-val-p' ><p>₹</p> <p>{overAllTotal.overallrate}</p></Col>
                            </Row>

                            {gstType === 'igst' ? <Row>
                                <Col className='col-subtotal-h2' ><h6>IGST:</h6></Col>
                                <Col className='col-subtotal-val-p' ><p>₹</p> <p>{overAllTotal.overalltax}</p></Col>
                            </Row> : <><Row>
                                <Col className='col-subtotal-h2' ><h6>CGST:</h6></Col>
                                <Col className='col-subtotal-val-p' ><p>₹</p> <p>{overAllTotal.overalltax / 2}</p></Col>
                            </Row>

                                <Row>
                                    <Col className='col-subtotal-h2' ><h6>SGST:</h6></Col>
                                    <Col className='col-subtotal-val-p' ><p>₹</p> <p>{overAllTotal.overalltax / 2}</p></Col>
                                </Row></>}




                            <Row>
                                <Col><div className='divider-2'></div></Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className='row-r3' >
                        <Col></Col> {/* First Section */}

                        <Col>  {/* Second Section */}
                            <Row>
                                <Col className='col-subtotal-h2' ><h5>Total:</h5></Col>
                                <Col className='col-subtotal-val-p' ><h5>₹</h5> <h5>{overAllTotal.overallamount}</h5></Col>
                            </Row>
                        </Col>
                    </Row>

                </div>

                <div>
                    <Row className='row-r4' >
                        <Col></Col>
                        <Col>

                            <Row>
                                <Col></Col>
                                <Col>
                                    <Stack direction='vertical' className='stack-'>
                                        <input
                                            type="file"
                                            onChange={handleSignatureImageChange}
                                            accept="image/png, image/jpeg"
                                            style={{ display: 'none' }}
                                            ref={fileSignatureInputRef}
                                            multiple={false}
                                        />
                                        <div className="image-wrapper2" >
                                            <img
                                                className='logo-image'
                                                src={selectedSignatureImage || signatureLogo}
                                                alt="Placeholder Image"
                                                onClick={handleSignatureImageClick}

                                            />
                                        </div>



                                        {selectedSignatureImage ? <button className='remove-button' onClick={() => { setSelectedSignatureImage(null); SetSignatureImage({ imageSrc: null, imageHeight: 0, imageWidth: 0 }) }} style={{ maxWidth: '300px' }}>Remove logo</button> : null}
                                    </Stack>

                                </Col>

                            </Row>


                        </Col>
                    </Row>
                </div>

                <div className='divider'></div>

                <Stack className='stack-notes'>
                    <h6>Additional Notes / Terms</h6>
                    <input value={notes} onChange={e => { setNotes(e.target.value) }} type='text' maxLength="2000" className='input-template input-field' placeholder='Notes / Terms' />
                </Stack>

            </div>

            {/* IGST/GST select option */}
            <div className='element4'>
                <Row>
                    <Col >
                        <div className='col-gst-select' value={gstType} onChange={handleGstTypeChange}>
                            <Form.Select aria-label="Select Tax Type">
                                <option value="igst">IGST</option>
                                <option value="cgst">CGST + SGST</option>

                            </Form.Select>

                        </div>

                    </Col>


                </Row>

            </div>



            {/* Bank Details Section */}
            <div className='element4'>
                <Row>
                    <Col >
                        <div className='col-bank-details'>

                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                label="Add Bank Details"
                                className='form-check'
                                checked={isBankCheck}
                                onChange={handleBankToggle}
                            />

                            <div style={{ display: isBankCheck ? 'block' : 'none' }} className='div-bank-info'>
                                <Stack direction='vertical'>
                                    <input placeholder='Account Holder Name' value={bankDetails.accountholdername} onChange={e => { handleBankDetails(e, 'accountholdername') }} className={`input-template ${bankValidate.accountholdername ? '' : 'custom-border'}`} type='text' />
                                    <input placeholder='Bank Account Number' value={bankDetails.accountnumber} onChange={e => { handleBankDetails(e, 'accountnumber') }} className={`input-template ${bankValidate.accountnumber ? '' : 'custom-border'}`} type='text' />
                                    <input placeholder='Bank Name' value={bankDetails.accountifsc} onChange={e => { handleBankDetails(e, 'accountifsc') }} className={`input-template ${bankValidate.accountifsc ? '' : 'custom-border'}`} type='text' />
                                    <input placeholder='IFSC Code' value={bankDetails.bankname} onChange={e => { handleBankDetails(e, 'bankname') }} className={`input-template ${bankValidate.bankname ? '' : 'custom-border'}`} type='text' />
                                </Stack>

                            </div>
                        </div>

                    </Col>


                </Row>

            </div>




            {/* Buy me a coffee section */}
            <div className='element3'>
                <Row>
                    <Col>
                        <a href="https://www.buymeacoffee.com/rohitsinghpro" target="_blank"><img src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" alt="Buy Me A Coffee" className='img-buy-me' /></a>
                    </Col>
                    <Col className='col-download-btn'>

                        <button onClick={GeneratePDFFromData} className='download-btn'> Download <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                            <path fillRule="evenodd" d="M13 11.15V4a1 1 0 1 0-2 0v7.15L8.78 8.374a1 1 0 1 0-1.56 1.25l4 5a1 1 0 0 0 1.56 0l4-5a1 1 0 1 0-1.56-1.25L13 11.15Z" clipRule="evenodd" />
                            <path fillRule="evenodd" d="M9.657 15.874 7.358 13H5a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2h-2.358l-2.3 2.874a3 3 0 0 1-4.685 0ZM17 16a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H17Z" clipRule="evenodd" />
                        </svg>
                        </button>

                    </Col>

                </Row>

            </div>


            <AlertBox istate={modalstate} setstate={SetModalState} />

        </div >
    )
});


export default Theme2;