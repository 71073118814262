import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import '../CSS/aboutus.css'

export default function AboutUs() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <div className='div-main-about'>
            <Container className='container-main-about'>

                <div className='about-main-div'>
                    <div>
                        <h1>
                            Freeinvoice.live is your home for generating unlimited quotations and invoices without any watermarks or unwanted branding.
                        </h1>
                        <p>The intention of creating this free invoice generator is to help people who are in a rush and want to generate an invoice or quotation without going through any unwanted registration process or getting charged after reaching a certain invoice generation limit.
                        </p>
                        <p>Freeinvoice.live is the world's only platform that provides a quick invoice generation service for 100% free.</p>

                    </div>

                    <div>
                        <h2>GST Invoice and Quotation </h2>
                        <p>
                            The top priority was to create a proper GST-based invoice generation system, as I could never find one that is 100% free to use and allows invoice generation without being hassled by an unwanted initial process, only to find out that the service is limited.
                        </p>

                    </div>

                    <div>
                        <p>If you find this free tool useful, I’d be grateful for a small token of appreciation, like a cup of coffee. Your support helps keep me motivated. Thank you!</p>
                        <a href="https://www.buymeacoffee.com/rohitsinghpro" target="_blank"><img src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" alt="Buy Me A Coffee" className='img-buy-me' /></a>
                    </div>

                </div>

            </Container>
        </div>
    )
}
