import './App.css';
import MainNavbar from './components/MainNavbar';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomeComp from './components/HomeComp';
import Footer from './components/Footer';
import Quotation from './Pages/Quotation';
import AboutUs from './Pages/AboutUs';
import Terms from './Pages/Terms';
import Privacy from './Pages/Privacy';
import NativeAds from './components/NativeAds';
import { Helmet } from 'react-helmet';
import BannerAd from './components/BannerAds';









function App() {

  return (
    <>
      <Router>


        <MainNavbar title='Freeinvoice.live' />
        <Routes>
          <Route path="/" exact element={<HomeComp />} />
          <Route exact path="/quotation-generator" element={<Quotation />} />
          <Route exact path="/about" element={<AboutUs />} />
          <Route exact path="/terms-of-use" element={<Terms />} />
          <Route exact path="/privacy-policy" element={<Privacy />} />
        </Routes>
        <div className='app-ad-div-parent'>
          <div className='app-ad-div'>
            <NativeAds />
          </div>
        </div>
        <Footer />
      </Router>

    </>
  );
}

export default App;
