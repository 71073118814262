import React from 'react'
import '../CSS/topbar.css'
import { Button, Stack } from 'react-bootstrap'
import { Link } from 'react-router-dom';

export default function TopBar2() {
    return (
        <div className='top-bar-element'>

            <Stack>
                <h1 className='top-bar-heading'>100% Free GST Quotation Generator</h1>
                <p className='top-bar-para'>Freeinvoice.live is a completely free online GST Quotation generator. Our free quotation generator does not require users to log in or register. This Quotation app does not add any unnecessary watermark.
                    In short, no login, no watermark, and a totally free online GST quotation generator. The output of the file is a PDF.
                    This app also allows you to generate unlimited Quotations.</p>

                <p className='top-bar-para'> Freeinvoice.live is the best free online invoice/Quotation generator app. Creating invoices/Quotation in this free app is very easy and straightforward. Enjoy our lifetime free invoice/bill/quotation generator.</p>

                <h5 className='top-bar-para'>Looking for something else? Try our free Invoice generator.</h5>
                <nav><Link to="/" className='link'> <Button className='button-quotation'>Free Invoice Generator</Button></Link></nav>
            </Stack>
        </div>

    )
}
