import React, { useEffect } from 'react'
import '../CSS/terms.css'
import { Container } from 'react-bootstrap'


export default function Terms() {


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='main-div-terms'>
            <Container>
                <div className='content-holder-terms'>
                    <h1>Terms of Use</h1>
                    <h6 className='mb-4'>Effective Date: 20-07-2024</h6>

                    <p>Welcome to Freeinvoice.live. By accessing or using our website, you agree to comply with and be bound by these Terms of Use. Please read these terms carefully before using our services.
                    </p>

                    <h5>1. Acceptance of Terms</h5>
                    <p>By accessing and using Freeinvoice.live ("the Website"), you accept and agree to be bound by the terms and provisions of this agreement. If you do not agree to abide by these terms, please do not use this Website.
                    </p>

                    <h5>2. Description of Service
                    </h5>
                    <p>Freeinvoice.live is a client-based application that allows users to generate invoices locally on their devices. The service is provided free of charge and does not require user registration or the submission of personal information.
                    </p>

                    <h5>3. User Responsibilities</h5>
                    <p>As a user of this Website, you agree to use the service only for lawful purposes. You are responsible for ensuring that your use of the Website does not violate any applicable laws or regulations.</p>

                    <h5>4. Intellectual Property</h5>
                    <p>All content on Freeinvoice.live, including text, graphics, logos, and software, is the property of Freeinvoice.live or its content suppliers and is protected by international copyright laws. Unauthorized use of this content is prohibited.
                    </p>

                    <h5>5. Third-Party Ads</h5>
                    <p>The Website may display advertisements from third-party advertisers. These advertisers are solely responsible for the content of their ads, and Freeinvoice.live is not liable for any claims or damages arising from these advertisements.
                    </p>

                    <h5>6. No Warranties</h5>
                    <p>Freeinvoice.live is provided "as is" and "as available" without any warranties of any kind, either express or implied. We do not guarantee that the Website will be error-free, uninterrupted, or free from viruses or other harmful components.
                    </p>


                    <h5>7. Limitation of Liability
                    </h5>
                    <p>In no event shall Freeinvoice.live, its owners, or affiliates be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of the Website. This includes, but is not limited to, damages for loss of profits, use, data, or other intangible losses.
                    </p>

                    <h5>8. Indemnification
                    </h5>
                    <p>You agree to indemnify and hold harmless Freeinvoice.live, its owners, and affiliates from any claims, damages, liabilities, costs, or expenses (including legal fees) arising from your use of the Website or your violation of these Terms of Use.
                    </p>


                    <h5>9. Changes to Terms
                    </h5>
                    <p>We reserve the right to modify these Terms of Use at any time. Any changes will be effective immediately upon posting on the Website. Your continued use of the Website after any such changes constitutes your acceptance of the new terms.</p>


                    <h5>10. Governing Law
                    </h5>
                    <p>These Terms of Use are governed by and construed in accordance with the laws of the country in which Freeinvoice.live operates, without regard to its conflict of law principles.</p>

                    <h5>11. Contact Information
                    </h5>
                    <p>If you have any questions about these Terms of Use, please contact us at:
                    </p>

                    <p><strong>Email:</strong> hello@rohitsingh.pro
                    </p>

                    <p>By using Freeinvoice.live, you acknowledge that you have read, understood, and agree to be bound by these Terms of Use.</p>

                </div>

            </Container>

        </div>
    )
}
